
@tailwind base;
@tailwind components;
@tailwind utilities;

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-table-thead .ant-table-cell {
  background-color: #ECF3FE;
  color:blue
}

.ant-table-tbody  {
  font-weight: 600;
  
}
.ant-switch-checked {
  background-color: #3e79f7 !important;
  
}
.ant-switch {
  background-color: #d0d4d7;
}
.ant-tag {
  color: #FFA043 !important;
  background-color: rgba(255, 160, 67, 0.1) !important;

}
.ant-menu-item-selected svg {
  fill: #3e79f7 !important;
}

